<template>
  <b-container fluid>
    <b-modal id="importCustomers" no-fade="no-fade" :title="$t('2023.import_customers')" scrollable hide-footer static centered no-close-on-backdrop>
      <div class="form-group chat-search-data m-0 row">
        {{ $t('2023.import_customers_text') }} <a href="https://api.12booky.com/import_customers.csv" style="margin-left:10px;">CSV</a>

        <v-progress-linear v-if="uploadStarted" color="green" indeterminate ></v-progress-linear>
        <div v-if="!uploadStarted" class="mt-2 col-12">
          <input type="file" ref="file4upload" accept=".csv" />
          <br>
          <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
            <input type="checkbox" class="custom-control-input" id="formType" v-model="removeCustomers">
            <label class="custom-control-label" for="formType">{{ $t('2023.remove_old_customers') }}</label>
          </div>
          <br>
          <br>
          <b-form-group class="col-sm-12 p-0" :label="$t('website.sendmessage')">
            <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
              <div class="custom-switch-inner">
                <input type="checkbox" class="custom-control-input bg-primary" id="sendmessage" v-model="theMessage">
                <label class="custom-control-label" for="sendmessage" :data-on-label="$t('payment.yes')" :data-off-label="$t('payment.no')">
                </label>
              </div>
            </div>
          </b-form-group>
          <b-form-group class="col-sm-12 p-0" :label="$t('website.message')" v-show="theMessage">
            <b-form-textarea name="about" v-model="theMessageText" style="line-height: 22px;" rows="5">
            </b-form-textarea>
          </b-form-group>

          <b-button class="ml-1" variant="primary" @click="uploadFile()"><i class="ri-contacts-book-upload-line"></i> {{ $t('2023.import_customers') }}</b-button>
        </div>
      </div>
    </b-modal>
    <b-row>
      <b-col sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('customer.customers_list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" :to="{ name: 'user.add' }"><i class="ri-user-3-fill"></i> {{ $t('customer.add_customer') }}</b-button>
            <b-button class="ml-1" variant="primary" @click="$bvModal.show('importCustomers')"><i class="ri-contacts-book-upload-line"></i> {{ $t('2023.import_customers') }}</b-button>
          </template>
          <template v-slot:body>
            <div style="width: 100%">
              <v-text-field
                v-model="search"
                append-icon="ri-search-line"
                :label="$t('customer.search')"
                single-line
                hide-details
                @input="searchCustomer"
              ></v-text-field>
            </div>
            <v-data-table
              :headers="headers"
              :items="rowData"
              :footer-props="{
              prevIcon: 'ri-arrow-left-line',
              nextIcon: 'ri-arrow-right-line'
            }"
            >
              <template v-slot:item.name="{ item }">
                <img :src="item.logo || require('../../assets/images/user/customer_placeholder.png')" style="object-fit: cover; width:50px; height:50px;" class="rounded mr-3"/>{{ item.bookingsValue }}
                {{ item.name }}
              </template>
              <template v-slot:item.actions="{ item }">
                <router-link :to="'/user/user-edit/' + item.token">
                  <button type="button" class="btn btn-default text-success">
                    <i class="ri-pencil-line"></i> {{ $t('customer.edit') }}
                  </button>
                </router-link>
                <button type="button" class="btn btn-default text-danger" @click="removeData(item.token)">
                  <i class="ri-delete-bin-line"></i> {{ $t('customer.delete') }}
                </button>
              </template>
            </v-data-table>

          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import 'ag-grid-community/dist/styles/ag-grid.min.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import { AllCommunityModules } from '@ag-grid-enterprise/all-modules'
import { core } from '../../config/pluginInit'
import Axios from 'axios'
import { API } from '@/config/api.config'

export default {
  name: 'UserList',
  mounted () {
    core.index()
    this.getLastCustomers()
  },
  data () {
    return {
      apiToken: localStorage.getItem('api_token') || '',
      companyToken: localStorage.getItem('company_token') || '',
      columnDefs: null,
      rowData: [],
      modules: AllCommunityModules,
      headers: [
        { text: this.$t('customer.name'), align: 'start', sortable: true, value: 'name' },
        { text: this.$t('customer.email'), value: 'email' },
        { text: this.$t('customer.phone'), value: 'phone' },
        { text: this.$t('customer_.cnp'), value: 'cnp' },
        { text: this.$t('customer.actions'), value: 'actions' }
      ],
      search: '',
      globalTimeout: null,
      uploadStarted: false,
      removeCustomers: false,
      theMessage: false,
      theMessageText: ''
    }
  },
  methods: {
    uploadFile () {
      if (this.$refs.file4upload.files[0] === undefined) {
        alert(this.$t('2023.pick_file'))
      } else {
        this.uploadStarted = true
        const formData = new FormData()
        formData.append('theFile', this.$refs.file4upload.files[0])
        formData.append('deleteCustomers', this.removeCustomers)
        formData.append('theMessage', this.theMessage)
        formData.append('theMessageText', this.theMessageText)
        Axios.post(API.API_IMPORT_CUSTOMERS, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then((response) => {
            if (response.data.status === 'fail') {
              core.showSnackbar('error', this.$t('2023.import_fail'))
            } else if (response.data.status === 'success') {
              this.uploadStarted = false
              this.getLastCustomers()
              this.$bvModal.hide('importCustomers')
              core.showSnackbar('success', this.$t('2023.import_success'))
            } else if (response.data.status === 'success_no_message') {
              this.uploadStarted = false
              this.getLastCustomers()
              this.$bvModal.hide('importCustomers')
              core.showSnackbar('success', 'Import success, but no rights to send messages')
            }
          })
          .catch((error) => {
            this.fileClicked = []
            console.error(error.response)
            if (error.response && error.response.status === 401) {
              this.$router.push({ name: 'auth1.login' })
            }
            this.uploadStarted = false
            this.getLastCustomers()
            this.$bvModal.hide('importCustomers')
            core.showSnackbar('error', this.$t('2023.import_fail'))
          })
      }
    },
    getLastCustomers () {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_GET_COMPANY_LAST_CUSTOMERS)
        .then((response) => {
          if (response.data.status === 'success') {
            this.rowData = response.data.message
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    searchCustomer () {
      const searchVal = this.search.toLowerCase()
      if (this.globalTimeout !== null) {
        clearTimeout(this.globalTimeout)
      }
      const newThis = this
      this.globalTimeout = setTimeout(function () {
        newThis.globalTimeout = null
        if (searchVal.length > 2) {
          newThis.getCompanyCustomers(searchVal)
        } else {
          newThis.rowData = []
        }
      }, 500)
    },
    getCompanyCustomers (searchData) {
      const search = {
        search: searchData
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_GET_COMPANY_CUSTOMERS_SEARCH, search)
        .then((response) => {
          if (response.data.status === 'success') {
            this.rowData = response.data.message
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    removeData (token) {
      this.$bvModal.msgBoxConfirm(this.$t('customer.delete_customer'))
        .then(value => {
          this.boxOne = value
          if (value === true) {
            const formData = {
              customer: token
            }
            Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
            Axios.post(API.API_DELETE_CUSTOMER, formData)
              .then((response) => {
                if (response.data.status === 'success') {
                  core.showSnackbar('success', this.$t('customer.customer_removed'))
                  this.$router.go()
                } else if (response.data.status === 'fail') {
                  core.showSnackbar('success', this.$t('customer.problem'))
                }
              })
              .catch((error) => {
                console.error(error.response)
                if (error.response.status === 401) {
                  this.$router.push({ name: 'auth1.login' })
                }
              })
          }
        })
    }
  }
}
</script>
